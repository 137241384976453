<template>
  <component :is="'b-card'">
    <b-tabs pills>
      <!-- Tab: Müşteri Hesap -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Müşteri Hesap</span>
        </template>
        <musteri-detail class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import Ripple from 'vue-ripple-directive'

// Custom Components
import MusteriDetail from './MusteriDetail.vue'

export default {
  components: {
    MusteriDetail,
  },
  directives: {
    Ripple,
  },
  methods: {
    fetchMusteri() {
      this.$store.dispatch('musteriCustomer/fetchMusteri')
    },
  },
  created() {
    this.fetchMusteri();
  },
}
</script>

<style>

</style>
