<template>
  <div>

    <!-- User Info: Input Fields -->
    <b-form v-if="musteriData">
      <b-row>

        <!-- Field: Müşteri Kodu -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Müşteri Kodu"
            label-for="sMusteriKodu"
          >
            <b-form-input
              id="sMusteriKodu"
              v-model="musteriData.sMusteriKodu"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Müşteri Adı -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Müşteri Adı"
            label-for="sMusteriAdi"
          >
            <b-form-input
              id="sMusteriAdi"
              v-model="musteriData.sMusteriAdi"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Vergi No -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Vergi No"
            label-for="sVergiNo"
          >
            <b-form-input
              id="sVergiNo"
              v-model="musteriData.sVergiNo"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Vergi Dairesi -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Vergi Dairesi"
            label-for="sMusteriVD"
          >
            <b-form-input
              id="sMusteriVD"
              v-model="musteriData.sMusteriVD"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Oluş Tarih -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Oluşturma Tarih"
            label-for="dtOlusTarih"
          >
          <b-form-input type="date" v-model="musteriData.dtOlusTarih" :disabled="true" />
          </b-form-group>
        </b-col>

      </b-row>

      <!-- Header: Müşteri İletişim Bilgileri -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
          class="text-primary"
        />
        <h4 class="mb-0 ml-50 text-primary">
          İletişim Bilgileri
        </h4>
      </div>

      <b-row class="mt-1">

        <!-- Field: Telefon -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Telefon"
            label-for="sMusteriTel"
          >
            <b-form-input
              id="sMusteriTel"
              v-model="musteriData.sMusteriTel"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Ülke -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Ülke"
            label-for="sMusteriUlke"
          >
            <b-form-input
              id="sMusteriUlke"
              v-model="musteriData.sMusteriUlke"
              placeholder="Türkiye"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Şehir -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Şehir"
            label-for="sMusteriSehir"
          >
            <b-form-input
              id="sMusteriSehir"
              v-model="musteriData.sMusteriSehir"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Semt -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Semt"
            label-for="sMusteriSemt"
          >
            <b-form-input
              id="sMusteriSemt"
              v-model="musteriData.sMusteriSemt"
              placeholder="İstanbul"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Müşteri Adresi -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Müşteri Adresi"
            label-for="sMusteriAdresi"
          >
            <b-form-input
              id="sMusteriAdresi"
              v-model="musteriData.sMusteriAdresi"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Müşteri Yetkili Bilgileri -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="ToolIcon"
          size="19"
          class="text-primary"
        />
        <h4 class="mb-0 ml-50 text-primary">
          Yetkili Bilgileri
        </h4>
      </div>

      <b-row class="mt-1">

        <!-- Field: Yetkili Adı -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Yetkili Adı"
            label-for="sYetkiliAd"
          >
            <b-form-input
              id="sYetkiliAd"
              v-model="musteriData.sYetkiliAd"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Yetkili Soyadı -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Yetkili Soyadı"
            label-for="sYetkiliSoyad"
          >
            <b-form-input
              id="sYetkiliSoyad"
              v-model="musteriData.sYetkiliSoyad"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Yetkili TC -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Yetkili TC"
            label-for="sTcNo"
          >
            <b-form-input
              id="sTcNo"
              v-model="musteriData.sTcNo"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Yetkili E-Posta -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Yetkili E-Posta"
            label-for="sYetkiliEMail"
          >
            <b-form-input
              id="sYetkiliEMail"
              v-model="musteriData.sYetkiliEMail"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Cep Telefon -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Cep Telefon"
            label-for="sYetkiliCep"
          >
            <b-form-input
              id="sYetkiliCep"
              v-model="musteriData.sYetkiliCep"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
export default {
  computed: {
    musteriData() {
      return this.$store.getters['musteriCustomer/getMusteriData']
    },
  },
}
</script>
